<template>
  <section>
    <b-container>
      <h2>Notifications</h2>
      <hr />

      <!-- Notifications -->
      <div>
        <b-card
          style="max-width: 20rem;"
          class="mb-2"
          v-for="activity in this.getActivities"
          :key="activity.id"
        >
          <b-card-text>
            {{ activity.description }}
          </b-card-text>

          <template v-slot:footer>
            <em>Created: {{ activity.created | formatLongDateTime }}</em>
          </template>
        </b-card>
      </div>
    </b-container>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "notifications",
  data() {
    return {
      rows: 0
    };
  },
  computed: {
    ...mapGetters(["getActivities"])
  },
  methods: {
    ...mapActions(["loadActivities"])
  },
  created() {
    this.loadActivities();
  }
};
</script>

<style></style>
